import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { classes } from '@silkpwa/module/util/classes';
import { connectProductReviews } from '@silkpwa/module/react-component/connect-product-reviews';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { injectProps } from '@silkpwa/redux';
import { useAccount } from '@silkpwa/module/account';
import { DropDown } from 'ui/component/drop-down';
import SearchBar from 'ui/component/native-reviews/display/search/search';
import { Snapshot } from 'ui/component/native-reviews/display/snapshot/snapshot';
import { StarRating } from 'ui/component/native-reviews/display/display/rating';
import { TimeDifference } from 'ui/component/native-reviews/display/display/time-diff';
import { MoreDetailsButton } from 'ui/component/native-reviews/display/display/more-details-btn';
import Verified from 'ui/component/native-reviews/display/display/verified';
import { ReviewMedias } from 'ui/component/native-reviews/display/display/review-images';
import { Section } from 'ui/component/native-reviews/components/section';
import { SectionTitle } from 'ui/component/native-reviews/components/section-title';
import { Footer } from 'ui/component/native-reviews/components/footer';
import { ReviewForm } from 'ui/component/native-reviews/review-form';
import { ScrollToElementBtn } from 'ui/component/native-reviews/hooks/scroll-to-element-btn';
import { ReviewHelpfulness } from 'ui/component/native-reviews/display//display/review-helpfulness';
import screenSwitch from 'ui/styles/screen-switch.css';
import { ReviewMediasSlider } from 'ui/component/native-reviews/display/display/review-medias-slider';
import { GET_REVIEW_MEDIA_SLIDER } from 'graphql/review/mutations/review-slider';
import { IProductReview } from 'graphql/review/reviews';
import { ShowLoader } from 'ui/component/show-loader';
import { InitCustomerSession } from 'ui/page/checkout-page/checkout-state';
import Pagination from 'ui/component/PaginationV1/Pagination';
import { useProductReviews, useReviewMetrics } from 'ui/component/native-reviews/hooks/useProductReviews';
import style from 'ui/component/native-reviews/display/style.css';

declare global {
    interface Window {
        isSessionInitialized?: boolean;
    }
}

window.isSessionInitialized = false;

const injectWindow = injectProps('window');

const isReviews = window => (
    window.location.hash &&
    window.location.hash.indexOf('#write-review') > -1
);

export const useReviewMediaSlider = (productId: number) => useQuery(GET_REVIEW_MEDIA_SLIDER, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: { productId },
});

export const ReviewDisplayImpl = ({
    productId,
    state,
    handleOptionChange,
    resetReviewAfterVote,
}) => {
    const t = usePhraseTranslater();
    const { isLoggedIn } = useAccount();
    const {
        data,
        loading,
    } = useReviewMediaSlider(productId);
    const [searchTerm, setSearchTerm] = useState('');
    const [starFilter, setStarFilter] = useState('');

    const reviewMediaSliderData = data?.reviewMediaSlider || [];
    const {
        productReviews,
        loading: loadingReviews,
        pageSize,
        totalReviews,
        refetch,
    } = useProductReviews(productId, 1, state.sort, searchTerm, starFilter);
    const {
        averageRating,
        averageRatingRound,
        averageRecommends,
        averageSizing,
        averageWidth,
        reviewsCount,
        ratingsCount,
        loading: loadingMetrics,
    } = useReviewMetrics(productId);
    const reviewMetrics = {
        averageRating,
        averageRatingRound,
        averageRecommends,
        averageSizing,
        averageWidth,
        reviewsCount,
        ratingsCount,
    };

    const recommendText = (value) => {
        if (value === 1) {
            return t('Yes, I would recommend to a friend');
        }
        if (value === 2) {
            return t('No, I would not recommend to a friend');
        }
        return 'Reviewer did not respond';
    };
    const [isSearchInitiated, setIsSearchInitiated] = useState(false);
    const [searchResultsCount, setSearchResultsCount] = useState(0);
    const [zeroResults, setZeroResults] = useState(false);
    const handleClearSearchTerm = () => {
        setSearchTerm('');
        setIsSearchInitiated(false);
        setSearchResultsCount(0);
        setZeroResults(false);
    };
    const onSearch = (term) => {
        setSearchTerm(term);
        setIsSearchInitiated(true);
        refetch({ searchTerm: term })
            .then(({ data }) => {
                const newTotal = data?.productReviews?.total;
                if (newTotal === 0) {
                    setZeroResults(true);
                }
                setSearchResultsCount(newTotal);
            });
    };
    const [currentPage, setCurrentPage] = useState(1);
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        refetch({ page });
    };

    const applyFilter = (star) => {
        setStarFilter(star);
        refetch({ starFilter: star })
            .then(({ data }) => {
                const newTotal = data?.productReviews?.total;
                if (newTotal === 0) {
                    setZeroResults(true);
                }
                setSearchResultsCount(newTotal);
            });
    };
    const clearFilter = () => {
        setStarFilter('');
    };

    function escapeRegExp(string: string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    let uniqueCounter = 0;

    function boldSearchResults(text, searchTerm) {
        if (!searchTerm) return text;
        uniqueCounter += 1;
        const currentCount = uniqueCounter;

        const parts = text.split(new RegExp(`(${escapeRegExp(searchTerm)})`, 'gi'));
        let localCounter = 0;
        return parts.map((part) => {
            localCounter += 1;
            const partKey = `key-${currentCount}-${localCounter}`;
            return part.toLowerCase() === searchTerm.toLowerCase() ? (
                <strong
                    className="searchTermFound"
                    key={partKey}
                >
                    {part}
                </strong>
            ) : part;
        });
    }

    const zeroResultsText = () => {
        if (zeroResults) {
            return t('Sorry No Results Found For Keyword: ');
        }
        return '';
    };

    const renderFilterMessage = () => {
        const hasFilter = !!starFilter;
        const hasSearchTerm = !!searchTerm;

        const handleClearStarFilter = () => setStarFilter('');

        return (
            <>
                {hasFilter && (
                    <div className={style.filterNotice}>
                        <h1 className={style.filterHeadline}>
                            Filters
                            Applied:
                        </h1>
                        {' '}
                        <div className={style.filterLabel}>
                            <button
                                onClick={handleClearStarFilter}
                                className={style.clearFilterButton}
                                aria-label="Clear filter for  star rating"
                                type="button"
                            >
                                <span className={style.filterClose}>
                                    <svg
                                        viewBox="9.5 9.5 21 21"
                                        focusable="false"
                                    >
                                        <g
                                            className={style.filterCloseGroup}
                                        >
                                            <circle
                                                className={style.closeCircle}
                                                cx="20"
                                                cy="20"
                                                r="10"
                                                stroke="#333"
                                                strokeWidth="1"
                                                fill="none"
                                            />
                                            <line
                                                className={style.closeLine}
                                                x1="15"
                                                y1="15"
                                                x2="25"
                                                y2="25"
                                                stroke="#333"
                                                strokeWidth="2"
                                            />
                                            <line
                                                className={style.closeLine}
                                                x1="25"
                                                y1="15"
                                                x2="15"
                                                y2="25"
                                                stroke="#333"
                                                strokeWidth="2"
                                            />
                                        </g>
                                    </svg>
                                </span>
                                <span className={style.filterLabelStars}>
                                    {starFilter}
                                    {' '}
                                    Stars
                                </span>
                            </button>
                        </div>
                    </div>
                )}
                {hasSearchTerm && (
                    <div className={style.filterLabel}>
                        <button
                            onClick={handleClearSearchTerm}
                            className={style.clearFilterButton}
                            aria-label="Clear filter for search term"
                            type="button"
                        >
                            <span className={style.filterClose}>
                                <svg viewBox="9.5 9.5 21 21" focusable="false">
                                    <g
                                        className={style.filterCloseGroup}
                                    >
                                        <circle
                                            className={style.closeCircle}
                                            cx="20"
                                            cy="20"
                                            r="10"
                                            stroke="#333"
                                            strokeWidth="1"
                                            fill="none"
                                        />
                                        <line
                                            className={style.closeLine}
                                            x1="15"
                                            y1="15"
                                            x2="25"
                                            y2="25"
                                            stroke="#333"
                                            strokeWidth="2"
                                        />
                                        <line
                                            className={style.closeLine}
                                            x1="25"
                                            y1="15"
                                            x2="15"
                                            y2="25"
                                            stroke="#333"
                                            strokeWidth="2"
                                        />
                                    </g>
                                </svg>
                            </span>
                            <span
                                className={style.filterLabelStars}
                            >
                                {searchTerm}
                            </span>
                        </button>
                    </div>
                )}
            </>
        );
    };

    const createOptions = () => [
        {
            id: 'date-desc',
            label: t('Most Recent'),
        },
        {
            id: 'rating-asc',
            label: t('Lowest Rated'),
        },
        {
            id: 'rating-desc',
            label: t('Highest Rated'),
        },
        {
            id: 'date-asc',
            label: t('Oldest'),
        },
    ];

    const initializeSession = async () => {
        await InitCustomerSession(false);
    };

    useEffect(() => {
        if (!isLoggedIn) {
            return;
        }

        if (window.isSessionInitialized === true) {
            return;
        }

        initializeSession().then(() => {
            window.isSessionInitialized = true;
        });
    }, [isLoggedIn]);

    return (
        <>
            {!isReviews(window) && (
                <Section
                    id="review-display"
                    className={classes(style.reviewDisplay, style.rdDisplayDesktop)}
                >
                    {(productReviews.length > 0 || zeroResults || loadingReviews) && (
                        <SectionTitle
                            id="rd-main-header"
                            className={classes(
                                style.rdMainHeader,
                                style.rdMainHeaderWithFilters,
                            )}
                        >
                            {!loading && (
                                <div className={style.rdReviewHeaderSorts}>
                                    <div className={screenSwitch.showOnDesktop}>
                                        <ReviewMediasSlider
                                            reviewMediaSliderData={reviewMediaSliderData}
                                            itemsClassName={style.itemsClass}
                                            arrowClassName={style.arrowClass}
                                            show={6}
                                        />
                                    </div>
                                    <div
                                        className={`${screenSwitch.showOnLargeMobile} ${screenSwitch.showOnMediumMobile}`}
                                    >
                                        <ReviewMediasSlider
                                            reviewMediaSliderData={reviewMediaSliderData}
                                            itemsClassName={style.itemsClass}
                                            arrowClassName={style.arrowClass}
                                            show={3}
                                        />
                                    </div>
                                    <div className={screenSwitch.showOnSmallMobile}>
                                        <ReviewMediasSlider
                                            reviewMediaSliderData={reviewMediaSliderData}
                                            itemsClassName={style.itemsClass}
                                            arrowClassName={style.arrowClass}
                                            show={2}
                                        />
                                    </div>
                                    <div className={screenSwitch.showOnVerySmallMobile}>
                                        <ReviewMediasSlider
                                            reviewMediaSliderData={reviewMediaSliderData}
                                            itemsClassName={style.itemsClass}
                                            arrowClassName={style.arrowClass}
                                            show={1}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className={style.rdSortGroup}>
                                <Snapshot
                                    applyFilter={applyFilter}
                                    clearFilter={clearFilter}
                                    filter={starFilter}
                                    reviewMetrics={reviewMetrics}
                                />
                            </div>
                            <div className={style.rdReviewHeaderSorts}>
                                <div className={style.rdSortGroup}>
                                    <SearchBar
                                        onSearch={onSearch}
                                        handleClearSearch={handleClearSearchTerm}
                                        isSearchInitiated={isSearchInitiated}
                                        searchResultsCount={searchResultsCount}
                                        searchTerm={searchTerm}
                                        reviewMetrics={reviewMetrics}
                                    />
                                </div>
                            </div>
                            <div
                                className={style.rdReviewHeaderSorts}
                                id={style.rdDropdown}
                            >
                                <div className={style.rdSortGroup}>
                                    <DropDown
                                        label={t('Sort by')}
                                        options={createOptions()}
                                        selected={state.sort}
                                        handleSelect={handleOptionChange}
                                    />
                                </div>
                            </div>
                            <div
                                className={style.filterSection}
                            >
                                {zeroResultsText()}
                                {' '}
                                {renderFilterMessage()}
                            </div>
                        </SectionTitle>
                    )}
                    {(loadingReviews || loadingMetrics || loading) && (
                        <div className={style.sliderLoader}>
                            <ShowLoader message="LOADING..." />
                        </div>
                    )}
                    {productReviews.slice(0, pageSize)
                        .map((review: IProductReview) => {
                            // prevent bad data or empty rating from crashing
                            const ratingValue = Array.isArray(review.rating_votes) && review.rating_votes.length > 0
                                ? review.rating_votes[0].value
                                : null;
                            return (
                                <div
                                    key={review.review_id}
                                    className={classes(style.review, `${style.review}-${review.review_id}`)}
                                >
                                    <SectionTitle
                                        className={classes(style.rdHeader, style.rdContentBlock)}
                                        id={style.starsHeadline}
                                    >
                                        <div className={style.rdStarRating}>
                                            <div
                                                className={classes(style.snippetStars, style.snippetStarsPng)}
                                                role="img"
                                                aria-label={ratingValue !== null ? `Rated ${ratingValue} out of 5 stars` : 'Rating not available'}
                                            >
                                                <div
                                                    aria-hidden="true"
                                                    className={style.ratingStars}
                                                >
                                                    <StarRating
                                                        rating={ratingValue !== null ? ratingValue : 0}
                                                    />
                                                </div>
                                                <div
                                                    aria-hidden="true"
                                                    className={style.snippetRatingDecimal}
                                                >
                                                    {ratingValue !== null ? ratingValue : 'N/A'}
                                                </div>
                                            </div>
                                        </div>
                                        <h2
                                            className={style.rdReviewHeadline}
                                            data-tab-index="-1"
                                            id={`rd-review-headline-${productId}`}
                                        >
                                            {review.title}
                                        </h2>
                                    </SectionTitle>
                                    <Section className={classes(style.rdDescription, style.rdContentBlock)}>
                                        <p className={style.rdDescriptionText}>
                                            {boldSearchResults(review.detail, searchTerm)}
                                        </p>
                                        <div
                                            className={classes(style.rdSideContentBlock, style.rdRight)}
                                        >
                                            <div
                                                className={classes(style.rdReviewerDetails,
                                                    style.rdInnerSideContentBlock)}
                                            >
                                                <p className={classes(style.rdDetails, style.rdAuthorSubmissionDate)}>
                                                    <span className={style.rdBold}>
                                                    Submitted
                                                    </span>
                                                    <span
                                                        data-datetime={review.created_at}
                                                    >
                                                        <TimeDifference
                                                            createdAt={review.created_at}
                                                        />
                                                    </span>
                                                </p>
                                                <p className={classes(style.rdDetails, style.rdAuthorNickname)}>
                                                    <span className={style.rdBold}>
                                                    By&nbsp;
                                                    </span>
                                                    <span>{review.nickname}</span>
                                                </p>
                                                <p className={classes(style.rdDetails, style.rdAuthorLocation)}>
                                                    <span>
                                                        <span
                                                            className={style.rdBold}
                                                        >
                                                        From&nbsp;
                                                        </span>
                                                        <span>{review.location}</span>
                                                    </span>
                                                </p>
                                                {review.verified_buyer === 1 &&
                                                    <Verified />}
                                            </div>
                                        </div>
                                    </Section>
                                    <MoreDetailsButton
                                        widthValue={review.width}
                                        sizingValue={review.sizing}
                                    />
                                    <ReviewMedias review={review} />
                                    <Footer
                                        className={classes(style.rdFooter, style.rdAuthorLocation)}
                                    >
                                        <p className={classes(style.rdBottomline, style.rdInnerContentBlock)}>
                                            <span className={style.rdBold}>
                                            Bottom Line
                                            </span>
                                            <span>
                                                {recommendText(review.recommend)}
                                            </span>
                                        </p>
                                    </Footer>
                                    <ReviewHelpfulness
                                        key={review.review_id}
                                        isLoggedIn={isLoggedIn}
                                        review={review}
                                        resetReview={resetReviewAfterVote}
                                        fetchProductReviews={refetch}
                                        productId={productId}
                                    />
                                </div>
                            );
                        })}
                    {productReviews.length > 0 ? (
                        <Footer className={style.rdMainFooter}>
                            <div className={style.rdContentBlock}>
                                <ScrollToElementBtn
                                    elementId="review-display"
                                    className={classes(
                                        style.rdToTop,
                                        style.frdFlagReviewBtn,
                                        style.underline,
                                    )}
                                    btnText="Back to Top"
                                    ariaLabel="Back to Top"
                                />
                            </div>
                        </Footer>
                    ) : (
                        !zeroResults && !loadingReviews && (
                            <Footer className={style.rdMainFooterNoReviews}>
                                <div className={style.rdContentBlock}>
                                    <Section
                                        className={classes(
                                            style.reviewDisplay,
                                            style.rdDisplayDesktop,
                                        )}
                                    >
                                        <div className={style.rdNoReviews}>
                                            <a
                                                href={`${window.location.href}#write-review`}
                                                className={style.snippetWriteReviewLink}
                                                rel="nofollow"
                                            >
                                                Write the First Review
                                            </a>
                                        </div>
                                    </Section>
                                </div>
                            </Footer>
                        )
                    )}
                    <div className={style.paginationCntCss}>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={Math.ceil(totalReviews / pageSize)}
                            onPageChange={handlePageChange}
                            scrollToElementId="review-display"
                        />
                    </div>
                </Section>
            )}
            {isReviews(window) && (
                <span className={style.reviewFormPageLink}>
                    <ReviewForm productId={productId} />
                </span>
            )}
        </>
    );
};

const ConnectedReviewDisplay = injectWindow(connectProductReviews(ReviewDisplayImpl));

export { ConnectedReviewDisplay as ReviewDisplay };
